.App {
  min-height: 100vh;
  background-image: url("./assets/pink-floyd-background.jpg");
  background-size: cover;
  background-color: #cccccc;
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.container {
  height:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 30px 0 30px;
  color: rgb(229, 245, 230);
  position: relative;
  font-weight: 700;
}

.authed-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.header-container {
  background-color: #575ec6c9;
  padding: 40px;
  border-radius: 20px;
  max-width:fit-content;
  margin-left:auto;
  margin-right: auto;
  margin-top: 30px;
}

.machine-container, .mint-container {
  margin-top: 40px;
  background-color: #575ec6c9;
  padding: 15px;
  border-radius: 20px;
  max-width:fit-content;
  margin-left:auto;
  margin-right: auto;
}

.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  bottom: 30px;
  left: 50%;
  margin-top: 70px;
  background-color: #575ec6c9;
  padding: 20px;
  border-radius: 20px;
  max-width:fit-content;
  -webkit-transform: translate(-50%,0);
  -ms-transform: translate(-50%,0);
  transform: translate(-50%,0);
}

.header {
  margin: 0;
  font-size: 50px;
  font-weight: bold;
}

.sub-text {
  font-size: 25px;
}

.gradient-text {
  background: -webkit-linear-gradient(left, #60c657 30%, #35aee2 60%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cta-button {
  height: 45px;
  border: 0;
  width: auto;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.connect-wallet-button {
  color: white;
  background: -webkit-linear-gradient(left, #ff8867, #ff52ff);
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
}

.mint-button {
  background: -webkit-linear-gradient(left, #4e44ce, #35aee2);
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
  margin-left: 10px;
}



.twitter-logo {
  width: 35px;
  height: 35px;
}

.footer-text {
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.connected-container input[type='text'] {
  display: inline-block;
  padding: 10px;
  width: 50%;
  height: 60px;
  font-size: 16px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 10px;
  margin: 50px auto;
}

.connected-container button {
  height: 50px;
}

/* KeyFrames */
@-webkit-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
