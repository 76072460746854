.machine-container {
  display: flex;
  flex-direction: column;
}

.gif-container {
  display: flex;
  flex-direction: column;
}

.gif-grid {
  display: flex;
  flex-direction: row;
  grid-gap: 1.5rem;
  justify-items: center;
  margin: 0;
  padding: 20px 0 20px 0;
}

.gif-item {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-self: center;
  align-self: center;
}

.gif-item img {
  width: 400px;
  height: 400px;
  border-radius: 10px;
  object-fit: cover;
}
